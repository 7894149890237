"use client"

import {
	createContext,
	useCallback,
	useContext,
	useState,
} from 'react'
import OfferListItem from '@components/lists/OfferListItemNew'
import Icon from '@components/shared/Icon'
import { EmptyBuyNew as NotFoundMessage } from '@components/shared/EmptyBuyNew'
import useMediaQueryBreakpoint from '@hooks/useMediaQueryBreakpoint'
import BREAKPOINTS from '@constants/breakpoints'
import useT from '@hooks/useTranslation'

import styles from './OfferListItemView.module.css'

const VIEWS = {
	GRID: 'grid',
	LIST: 'list',
	SMALL_GRID: 'grid-small',
}

const ICONS = [
	{ view: VIEWS.GRID, icon: 'grid_view' },
	{ view: VIEWS.LIST, icon: 'view_list' },
]

const OfferListViewContext = createContext([])
const useOfferListViewContext = () => useContext(OfferListViewContext)

function StateProvider({ children, defaultState }) {
	const [view, setView] = useState(defaultState || VIEWS.GRID)

	return (
		<OfferListViewContext.Provider value={[view, setView]}>
			{children}
		</OfferListViewContext.Provider>
	)
}

function SelectBar({ children }) {
	return <div className={styles['select-bar']}>{children}</div>
}
function SelectBarLeft({ children }) {
	return <div className={styles['select-bar-left']}>{children}</div>
}
function SelectBarRight({ children }) {
	return <div className={styles['select-bar-right']}>{children}</div>
}

function ToggleViewActionIcons() {
	const [_view, setView] = useOfferListViewContext()
	const onMobile = useMediaQueryBreakpoint(BREAKPOINTS.showHamburger)

	const handleClick = useCallback(
		(view) => (e) => {
			e.preventDefault()
			if (_view !== view) setView(view)
		},
		[_view, setView],
	)

	if (onMobile) return null
	return (
		<div>
			{ICONS.map(({ view, icon }) => {
				const className = _view == view ? styles.active : 'clickable'
				return (
					<span
						className={className}
						key={view}
						onClick={handleClick(view)}
						role="button"
					>
						<Icon>{icon}</Icon>
					</span>
				)
			})}
		</div>
	)
}

function OfferListItemView({ children, onMobile }) {
	const [view] = useOfferListViewContext()
	const _onMobile = useMediaQueryBreakpoint(BREAKPOINTS.showHamburger, onMobile)

	if (_onMobile || view === VIEWS.GRID)
		return <Grid>{children}</Grid>
	if (view === VIEWS.LIST)
		return <List>{children}</List>
	if (view === VIEWS.SMALL_GRID)
		return <SmallGridView>{children}</SmallGridView>
	return null
}

function Grid({ children }) {
	return <div className={styles.grid}>{children}</div>
}

function List({ children }) {
	return (
		<div className={styles.list} data-is-list="true">
			{children}
		</div>
	)
}

function SmallGridView({ children }) {
	return <div className={styles['grid-small']}>{children}</div>
}

function ViewOffers({
	Empty = NotFoundMessage,
	GridItem = OfferListItem.GridItem,
	ListItem = OfferListItem.ListItem,

	ads,
	inject,
	offers,
	onMobile,
	showEmpty = true,

	...rest
}) {
	const [view] = useOfferListViewContext()
	const _onMobile = useMediaQueryBreakpoint(BREAKPOINTS.showHamburger, onMobile)

	const ViewListItem =
		_onMobile || view === VIEWS.GRID || view === VIEWS.SMALL_GRID
			? GridItem
			: ListItem

	const listedOffers = withAds(ads, (offers?.fallback || offers || []).map(offer => <ViewListItem key={offer._shortid} offer={offer} {...rest} />))

	if (
		!Array.isArray(offers) ||
		offers.length == 0 ||
		offers.notFound
	) {
		const hasFallback = Array.isArray(offers?.fallback) && listedOffers.length > 0

		return (
			<>
				{ showEmpty && <Empty inject={inject} /> }
				{hasFallback && <>
					<h4 style={{ fontSize: '17rem', gridColumn: '1/-1' }}>
						{useT('Inne ogłoszenia, które mogą Ci się spodobać')}:
					</h4>
					{listedOffers}
				</>}
			</>
		)
	}

	return <>{listedOffers}{inject}</>
}

function withAds (ads, offers) {
	if (!ads?.length) return offers || []
	let zipped = offers
	for (const ad of ads) {
		const { Ad, idx } = ad || {}
		if (Ad == null || idx == null) continue
		zipped = [...zipped.slice(0, idx), Ad, ...zipped.slice(idx)]
	}
	return zipped
}

SelectBar.Right = SelectBarRight
SelectBar.Left = SelectBarLeft

OfferListItemView.ViewOffers = ViewOffers
OfferListItemView.Grid = List
OfferListItemView.List = Grid
OfferListItemView.ToggleViewActionIcons = ToggleViewActionIcons
OfferListItemView.SelectBar = SelectBar
OfferListItemView.StateProvider = StateProvider
OfferListItemView.VIEWS = VIEWS

export default OfferListItemView
export {
	OfferListItemView,
	ViewOffers,
	List,
	Grid,
	ToggleViewActionIcons,
	SelectBar,
	SelectBarLeft,
	SelectBarRight,
	StateProvider,
	VIEWS,
}
