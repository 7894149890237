import { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useProgress, useSetProgress } from '@context/ProgressContext'
import MessageHelper from '@utils/messageHelper'
import useT from '@hooks/useTranslation'

const Form = ({ children, onSubmit, defaultValues, draft, forwardRef, injectInForm, progressbar }) => {
    const methods = useForm({ defaultValues })
    const { handleSubmit, formState, reset } = methods

    useEffect(() => {
        const isInvalid = formState.isSubmitted && !formState.isValid
        if (isInvalid) {
            const firstError = document.querySelector('[data-type="error"]')
            if (firstError) {
                firstError.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
            }
        }
    }, [formState.isSubmitted, formState.isSubmitting, formState.isValid])

    const setProgress = progressbar && useSetProgress()
    const getProgress = progressbar && useProgress
    const progress = getProgress && getProgress()
    const trwaPrzesylanie = useT('Zapis ogłoszenia nie jest możliwy w tej chwili. Trwa odczyt lub zapis zdjęć')

    const submitHandler = progressbar
        ? (data) => {
            if (progress !== null) {
                return MessageHelper.message(trwaPrzesylanie + '.')
            }
            onSubmit(data)
          }

        : onSubmit

    useEffect(() => {
        if (draft) setTimeout(() => reset(draft), 500)
    }, [reset, draft])

    return (
        <FormProvider {...methods} getProgress={getProgress} setProgress={setProgress}>
            <form onSubmit={handleSubmit(submitHandler)} ref={forwardRef} noValidate>
                { children }
                { injectInForm }
            </form>
        </FormProvider>
    )
}

export default Form