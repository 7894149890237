import Script from 'next/script'

export default function jsonLd (object) {
    return object
        ? <Script
            dangerouslySetInnerHTML={{ __html: JSON.stringify(object) }}
            id="json-ld"
            type="application/ld+json"
          />


        : null
}