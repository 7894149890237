const plural =
    (count, forms) => {
        const i = parseInt(count)
        if (i <= 0) return forms[2] || forms[1]
        if (i === 1) return forms[0]

        return i < 5 || (i > 21 && i % 10 < 5 && i % 10 > 1)
            ? forms[1]
            : forms[2] || forms[1]
    }


export default plural