import useFormMethodsWithLegacyFormSupport from '@components/form/new-inputs-for-context-form/useFormMethodsWithLegacySupport'
import useT from '@hooks/useTranslation'

import styles from 'styles/validation/Error.module.css'

const Error = ({ errorText, name, ...rest }) => {
    const methods = useFormMethodsWithLegacyFormSupport()
    const { formState: { errors }} = methods || {formState:{}}
    const error = errors[name]

    return errorText || error
        ? <ErrorMessage error={error} errorText={errorText} {...rest} />
        : null
}

function ErrorMessage ({ error, errorText, ...rest }) {
    const hasError = Boolean(error)
    const { message } = error || {}
    const text = useT(message || 'Popraw pole')

    return (
        <div className={hasError ? styles.err : undefined} data-type="error" {...rest}>
            {hasError ? text : errorText}
        </div>
    )
}

export default Error