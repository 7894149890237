import mix from '@utils/styles/mix'
import styles from './FormWizardContainer.module.css'

function InputGrid ({ children, className, four }) {
    return <div className={mix([styles['input-grid'], four && styles.four, className])}>
        {children}
    </div>
}

function FullWidthCell ({ children }) {
    return <div className={styles['full-width']}>
        {children}
    </div>
}

function FlexDistributed ({ children, ...rest }) {
    return <div className={styles['flex-distributed']} {...rest}>
        {children}
    </div>
}

InputGrid.FullWidthCell = FullWidthCell
InputGrid.FlexDistributed = FlexDistributed

export default InputGrid