const salonProxyMap = {
    '5fa51fa4fc3ff2437d740676': [ // fialkowski.samochody.pl
        'marcin.kwasniewski@fialkowski.pl',
        'adrian.lewkowicz@fialkowski.pl',
        'marcin.kwasniewski@fialkowski.subaru.pl',
        'marcin.kwasniewski@fialkowski.pl',
        'arkadiusz.dabrowski@fialkowski.pl',
        'salon@opel-fialkowscy.pl',
        'zielona-gora@kawasaki.pl',
    ]
}

export default salonProxyMap