import { useRouter } from 'next/router'
import Section from '@components/layout/Section'
import SearchFormContainer from '@components/form/SearchFormContainer'
import OfferListItemView from '@components/lists/OfferListItemView'
import Pagination from '@components/pagination/RouterPagination'
import useT from '@hooks/useTranslation'
import ButtonWrapper from '@components/salon/ButtonWrapper'
import {
	CONTENT_MAP,
	CONTENT_MAP_NAME_TO_KEY,
} from './constants/salonOffer'
import resolveAvailableListContent from './functions/resolveAvailableListContent'
import withSalonOffers from '@hoc/withSalonOffers'
import plural from '@utils/grammar/plural'

import filterStyles from './SalonOfferFilters.module.css'

const SalonOfferWithOffers = withSalonOffers(SalonOffer)

function SalonOfferMain({
	adCount,
	content,
	prefetchedOffers,
	salonId,
	...rest
}) {
	const router = useRouter()
	const availableContent = resolveAvailableListContent(
		content,
		adCount,
	)
	const activeContent =
		CONTENT_MAP_NAME_TO_KEY[router.query.zakladka] || 'all'

	const noContentToDisplay =
		availableContent.length < 1 ||
		(availableContent.length === 1 && prefetchedOffers.length < 1)

	if (noContentToDisplay) return null

	return (
		<SalonOfferWithOffers
			activeContent={activeContent}
			adCount={adCount}
			availableContent={availableContent}
			content={content}
			prefetchedOffers={prefetchedOffers}
			salonId={salonId}
			{...rest}
		/>
	)
}

function SalonOffer({
	availableContent,
	filters,
	id,
	offers,
	pagination,
	sort,
}) {
	// adCount, content, prefetchedOffers, salonId,
	const pelnaOferta = useT('Pełna oferta')
	const ogloszenia = [
		useT('ogłoszenie'),
		useT('ogłoszenia'),
		useT('ogłoszeń'),
	]
	const adCount = pagination?.totalDocs || offers.length

	return (
		<div>
			<h2 id={id} style={{ marginTop: 0, textAlign: 'center' }}>
				{pelnaOferta} ({adCount} {plural(adCount, ogloszenia)})
			</h2>

			<SearchFormContainer.StateProvider>
				<SearchFormContainer.MobileToggle
					scrollWindow={false}
					sort={sort}
					useAllways={true}
				>
					<Section.Box
						style={{ borderRadius: 'var(--border-radius)' }}
						white
					>
						<OfferButtons availableContent={availableContent} />
						<div className={filterStyles.filters}>{filters}</div>
					</Section.Box>
				</SearchFormContainer.MobileToggle>
			</SearchFormContainer.StateProvider>
			<br />

			<div>
				<OfferListItemView.StateProvider
					defaultState={OfferListItemView.VIEWS.SMALL_GRID}
				>
					<OfferListItemView>
						<OfferListItemView.ViewOffers
							linkWithHost={true}
							offers={offers}
						/>
					</OfferListItemView>
				</OfferListItemView.StateProvider>
				<br />
				<Pagination {...pagination} onTop={false} />
			</div>
			<br />
			<br />
		</div>
	)
}

function OfferButtons({ availableContent }) {
	const router = useRouter()
	return (
		<ButtonWrapper>
			{availableContent.map((key) => {
				const name = CONTENT_MAP[key]
				const tab = router.query.zakladka
				const active =
					name === tab || (!tab && key === 'all') ? 'true' : undefined

				const onClick = () => {
					const query = { ...router.query, zakladka: name }
					delete query.strona
					if (key === 'all') delete query.zakladka
					router.push({ query }, undefined, { scroll: false })
				}

				return (
					<button data-active={active} key={key} onClick={onClick}>
						{name}
					</button>
				)
			})}
		</ButtonWrapper>
	)
}

export default SalonOfferMain