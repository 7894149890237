import Image from "next/legacy/image"
import useT from '@hooks/useTranslation'

import styles from './EmptyNew.module.css'

export default function EmptyNew({
	fallbackText = 'Inne ogłoszenia, które mogą Ci się spodobać',
	iconSize = 80,
	iconSrc = '/new/empty-icon.jpg',
	showFallbackText = false,
	text = 'Nie odnaleziono ogłoszeń o podanych kryteriach',
	inject,
}) {
	const _fallbackText = useT(fallbackText)

	return (
		<div className={styles.empty}>
			<span>
				<Image
					alt="Nie znaleziono"
					height={iconSize}
					src={iconSrc}
					width={iconSize}
				/>
			</span>
			<br />
			<b style={{ color: 'var(--teal)' }}>{useT(text)}.</b>
			{inject}
			{showFallbackText && <p>{_fallbackText}:</p>}
		</div>
	)
}
