const AD_COUNT_KEYS = {
    all: 'all',
    brand_new: 'car_ads_new',
    used: 'car_ads_used',
    for_rent: 'car_ads_rent',
    motorcycles: 'motorcycle_ads',
    service: 'service_ads',
    parts: 'part_ads',
    tools: 'tool_ads',
    trucks: 'truck_ads',
    heavies: 'ciezarowes',
    agri: 'agri_ads',
}

const CONTENT_MAP = {
    all:         'Wszystkie',
    brand_new:   'Nowe samochody',
    used:        'Używane samochody',
    for_rent:    'Wynajem pojazdów',
    motorcycles: 'Motocykle',
    service:     'Serwis pojazdów',
    parts:       'Części i akcesoria',
    tools:       'Narzędzia',
    trucks:      'Samochody dostawcze',
    heavies:     'Samochody ciężarowe',
    agri:        'Maszyny rolnicze',
}

const CONTENT_MAP_NAME_TO_KEY = Object.keys(CONTENT_MAP)
    .reduce((acc, key) => {
        acc[CONTENT_MAP[key]] = key
        return acc
    }, {})

export {
    AD_COUNT_KEYS,
    CONTENT_MAP,
    CONTENT_MAP_NAME_TO_KEY,
}