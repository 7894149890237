import { useRouter } from 'next/router'
import { useEffect, useRef, useMemo, useState } from 'react'
import useSWR from 'swr'
import Form from '@components/form/FormWithContext'
import InputGrid from '@components/search/forms/FormWizardContainer'
import Input from '@components/form/context/Input'
import Sort from '@components/select/SortAlt'
import SpinnerBlock from '@components/spinner/SpinnerBlock'
import { apiFetcherPost as apiFetcher } from '@api/apiFetcher'
import Button from '@components/button/Button'
import objectToSearch from 'services/cron/utils/objectToSearch'

import markaAgri from '@components/search/inputs/markaAgri'
import modelAgri from '@components/search/inputs/modelNone'

import markaCar from '@components/search/inputs/markaCar'
import modelCar from '@components/search/inputs/modelCar'
import typCar from '@components/search/inputs/typCar'

import markaHeavy from '@components/search/inputs/markaHeavy'
import modelHeavy from '@components/search/inputs/modelHeavy'
import typHeavy from '@components/search/inputs/typHeavy'

import markaMoto from '@components/search/inputs/markaMotorcycle'
import modelMoto from '@components/search/inputs/modelMotorcycle'
import typMoto from '@components/search/inputs/typMotorcycle'

import markaTruck from '@components/search/inputs/markaTruck'
import modelTruck from '@components/search/inputs/modelTruck'
import typTruck from '@components/search/inputs/typTruck'

import useT from '@hooks/useTranslation'
import areDifferentObjects from '@utils/object/areDifferentObjects'

import salonProxyMap from 'db/repo/listAdsSalon/salonProxyMap'
import { clone } from '@utils/clone/clone'

import styles from '@components/form/new-inputs-for-context-form/NewFormInput.module.css'

const paliwo = {
	label: 'Paliwo',
	name: 'paliwo',
	hint: { url: '/fuel', exact: true },
}
const paliwoMoto = {
	label: 'Paliwo',
	name: 'paliwo',
	hint: { url: '/fuel_motor', exact: true },
}
const skrzyniaBiegow = {
	t: 'Skrzynia biegów',
	name: 'skrzynia-biegow',
	hint: { url: '/gearbox' },
}

const DEBOUNCE = 1200

const MAKE_ID = 0
const MODEL_ID = 1
const TYPE_ID = 2
const FUEL_ID = 3
const GEARBOX_ID = 4
const carInputs = [markaCar, modelCar, typCar, paliwo, skrzyniaBiegow]
const contentInputs = {
	agri: [markaAgri, modelAgri, null, paliwo],
	all: carInputs,
	brand_new: carInputs,
	used: carInputs,
	for_rent: carInputs,

	heavies: [markaHeavy, modelHeavy, typHeavy, paliwo],
	motorcycles: [markaMoto, modelMoto, typMoto, paliwoMoto],
	trucks: [markaTruck, modelTruck, typTruck, paliwo],

	// 'service'
	// 'parts'
	// 'tools'
}

let submitTimeout
const withSalonOffers = (TargetComponent) =>
	(function SalonOffers(props) {
		const router = useRouter()
		const formRef = useRef()

		const { salonId, activeContent, prefetchedOffers, ...rest } =
			props
		const [isFirstRender, setIsFirstRender] = useState(true)
		const [lastRouterQuery, setLastRouterQuery] = useState({})

		useEffect(() => {
			if (isFirstRender) {
				setIsFirstRender(false)
			}
		}, [isFirstRender])

		useEffect(() => {
			if (
				router.isReady &&
				areDifferentObjects(lastRouterQuery, router.query)
			) {
				setLastRouterQuery({ ...router.query })
			}
		}, [lastRouterQuery, router, router.query])

		const sort = lastRouterQuery.sortuj || 'Najnowsze'
		const page = parseInt(lastRouterQuery.strona || 1)

		const shouldFetch = page > 0
		const url = useMemo(
			() =>
				`/api/salon/${salonId}/offers/${activeContent}/${page}/${
					sort || 'Najnowsze'
				}${objectToSearch(lastRouterQuery, [
					'sortuj',
					'subdomain',
					'zakladka',
				])}`,
			[activeContent, lastRouterQuery, page, salonId, sort],
		)

		const { data } = useSWR(shouldFetch && url, apiFetcher)

		const { ads, pagination } = shouldFetch
			? data || {}
			: prefetchedOffers

		const [filters, sorter] = useMemo(
			() =>
				makeFilters(
					activeContent,
					lastRouterQuery,
					formRef,
					router,
					salonId,
					sort,
				),
			[activeContent, lastRouterQuery, router, salonId, sort],
		)

		return ads ? (
			<TargetComponent
				filters={filters}
				offers={ads}
				pagination={pagination}
				sort={sorter}
				{...rest}
			/>
		) : (
			<SpinnerBlock height={400} />
		)
	})

function makeFilters(
	activeContent,
	lastRouterQuery,
	formRef,
	router,
	salonId,
	sort,
) {
	if (!router.isReady) return null

	const marka = lastRouterQuery.marka || ''
	const model = lastRouterQuery.model || ''
	const typ = lastRouterQuery.typ || ''
	const paliwo = lastRouterQuery.paliwo || ''
	const skrzynia = lastRouterQuery['skrzynia-biegow'] || ''

	const changeParam = (name, value) => {
		const query = { ...lastRouterQuery }
		if (value) {
			query[name] = value
		} else {
			delete query[name]
		}
		router.push({ query }, undefined, { scroll: false })
	}

	const changeParams = (nameValues) => {
		const query = { ...lastRouterQuery }
		for (const name in nameValues) {
			const value = nameValues[name]
			if (value) query[name] = value
			else delete query[name]
		}
		router.push({ query }, undefined, { scroll: false })
	}

	const captureParam = (name) => (e) => {
		changeParam(name, e.target ? e.target.value : e)
	}

	const navigate = () => {
		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true }),
		)
	}

	return [
		<Form
			defaultValues={{
				marka,
				model,
				[typeField(activeContent)]: typ,
				paliwo,
				skrzynia,
			}}
			forwardRef={formRef}
			key={`${activeContent}-form`}
			onSubmit={(data) => {
				clearTimeout(submitTimeout)
				submitTimeout = setTimeout(
					() =>
						changeParams({
							marka: data.marka || '',
							model: data.model || '',
							typ: data['typ-nadwozia'] || data['typ'] || '',
							paliwo: data.paliwo || '',
							'skrzynia-biegow': data['skrzynia-biegow'] || '',
							strona: '',
						}),
					DEBOUNCE,
				)
			}}
		>
			<InputGrid>
				{resolveInput(
					activeContent,
					marka,
					MAKE_ID,
					navigate,
					salonId,
				)}
				{resolveInput(
					activeContent,
					model,
					MODEL_ID,
					navigate,
					salonId,
				)}
				{resolveInput(activeContent, typ, TYPE_ID, navigate, salonId)}
				{resolveInput(
					activeContent,
					paliwo,
					FUEL_ID,
					navigate,
					salonId,
				)}
				{resolveInput(
					activeContent,
					skrzynia,
					GEARBOX_ID,
					navigate,
					salonId,
				)}
			</InputGrid>
			<br/>
			<Button icon="zoom" type="submit" teal>{useT('Wyszukaj')}</Button>
		</Form>,
		<Sort
			defaultValue={sort}
			key="sort"
			onChange={captureParam('sortuj')}
		/>,
	]
}

function resolveInput(
	activeContent,
	defaultValue,
	id,
	navigate,
	salonId,
) {
	const inputs = contentInputs[activeContent] || []
	const _input = inputs[id]
	if (!_input) return null

	const isSpecial = Boolean(salonProxyMap[salonId])
	const input = clone(_input)

	if (input.hint && [MAKE_ID, MODEL_ID].includes(id)) {
		input.hint.url = `${input.hint.url}/${isSpecial ? 'spc-' : ''}user/${salonId}`
	}

	if (!input.label && input.t) {
		input.label = useT(input.t)
	}

	return (
		<Input
			{...input}
			defaultValue={defaultValue}
			// onImmediateChange={navigate}
			style={styles}
			isSearchInput
		/>
	)
}

function typeField(activeContent) {
	return activeContent === 'motorcycles' ? 'typ' : 'typ-nadwozia'
}

export default withSalonOffers