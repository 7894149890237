import { createContext, useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Progress from '@components/progress/Progress'

const SetProgressContext = createContext(()=>null)
const GetProgressContext = createContext(null)
const ProgressContextProvider = ({ children, defaultValue = null }) => {
    const [progress, setProgress] = useState(defaultValue)
    return (
        <SetProgressContext.Provider value={setProgress}>
            <GetProgressContext.Provider value={progress}>
                {children}
            </GetProgressContext.Provider>
        </SetProgressContext.Provider>
    )
}

const useProgress = () => {
    return useContext(GetProgressContext)
}

const useSetProgress = () => {
    return useContext(SetProgressContext)
}

function PhotoUploadProgress () {
    const { getProgress } = useFormContext() || {}
    const progress = getProgress ? getProgress() : null
    const longActionInProgress = progress !== null

    return longActionInProgress
        ? <Progress progress={progress} />
        : <div style={{height:2}} />
}

ProgressContextProvider.PhotoUploadProgress = PhotoUploadProgress

export default ProgressContextProvider
export {
    useProgress,
    useSetProgress,
}