import { Children, useMemo, useState } from 'react'
import useTranslatedArray from '@hooks/useTranslatedArray'

import styles from './ViewSwapper.module.css'

export const useViewSwapper = function useViewSwapper (views) {
    const names = useTranslatedArray(Object.values(views))
    const [show, setShow] = useState(names[0])
    const showIdx = useMemo(() => names.findIndex(name => name === show), [names, show])

    const onClick = (name) => () => setShow(name)

    const ViewSwapper = useMemo(() => (function ViewSwapper({ children }) {
        return <div>
            <div className={styles.links}>{names.map(name => <span className={name === show ? styles.active : undefined} key={name} onClick={onClick(name)} role="button">{name}</span>)}</div>
            {Children.map(children, (child, idx) => <div key={idx} className={idx === showIdx ? styles.shown : styles.hidden}>{child}</div>)}
        </div>
    }), [names, show, showIdx])

    return ViewSwapper
}