import Empty from './EmptyNew'
import Icon from '@components/shared/Icon'
import { AddBuyCarButton } from './NavbarButtons'
import useT from '@hooks/useTranslation'

function BuyMessage() {
	return (
		<>
			<br />
			<b>
				{useT(
					'Dodaj ogłoszenie w kategorii Kupię, a wszystkie firmy dostaną powiadomienie',
				)}
				:
			</b>
			<br />
			<AddBuyCarButton>
				{useT('Kupię auto')}
				<Icon style={{ margin: 0 }}>arrow_right_alt</Icon>
			</AddBuyCarButton>
		</>
	)
}

function EmptyBuyNew({ inject, ...props }) {
	return <Empty {...props} inject={<><BuyMessage />{inject}</>} />
}

export { BuyMessage, EmptyBuyNew }
