import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Partner from './PartnerSalonComponent'
import Layout from '@components/layout/Main'
import Section from '@components/layout/Section'
import Container from '@components/layout/Container'
import Maybe from '@components/layout/Maybe'
import BreadcrumbBar from '@components/breadcrumbs/BreadcrumbBarNew'
import {
	Map,
	SalonDetails,
} from '@components/displayNew/DisplayOffer'
import DisplayContact from '@components/displayNew/DisplayContact'

import { useViewSwapper } from './ViewSwapper'
import SalonDescription from '@components/salon/SalonDescription'
import SalonOffer from '@components/salon/SalonOffer'
import Seo from 'src/seo/components/Seo'
import JsonLdSalon from 'src/seo/jsonld/JsonLdSalon'
import salonLink from '@utils/links/salonLink'

import useT from '@hooks/useTranslation'

import { FIRM_BASE_PAGE } from '@constants/routes'

const HOST = process.env.NEXT_PUBLIC_HOST
const OFFER_LIST_ID = 'pelna-oferta'
const VIEWS = {
	offer: 'Oferta',
	description: 'Opis',
}

const userDetailsDesktopStyle = {
	maxWidth: 'clamp(300px,40%,490px)',
}

function SalonComponent(props) {
	const isPartner = Boolean(props.salon.is_partner)
	return isPartner ? <Partner {...props} /> : <Salon {...props} />
}

function Salon({ salon, prefetchedOffers, seo, subdomain, visitor }) {
	const router = useRouter()

	const { _id, dealer = {}, ad_count = {}, alias } = salon || {}
	const { content = {}, firm_name } = dealer || {}
	const contentWithHeavies = { ...content, heavies: true } // <- always include heavies

	seo.canonical = salonLink(subdomain)

	const displayName = alias || firm_name

	const contactFragment = (
		<>
			<DisplayContact.HiddenNumber recipient={salon} />
			<br />
			<DisplayContact.HiddenEmail recipient={salon} />
		</>
	)

	const titleFragment = (
		<h1 style={{ wordBreak: 'break-word' }}>
			{displayName} <FacebookLink user={salon} />
		</h1>
	)

	const salonDetailsFragment = (
		<>
			<SalonDetails
				defaultAddress={salon?.dealer?.firm_city}
				inject={
					<>
						{contactFragment}
						<br />
					</>
				}
				offerLinkScrollTo={OFFER_LIST_ID}
				title={titleFragment}
				user={salon}
				visitor={visitor}
				nofirmname
			/>
		</>
	)

	const salonDescriptionFragment = (
		<Section gray>
			<Section.Box
				style={{ borderRadius: 'var(--border-radius)' }}
				white
			>
				<SalonDescription
					salon={salon}
					additionalTags={['figure', 'iframe']}
				/>
			</Section.Box>
		</Section>
	)

	const salonOfferFragment = (
		<Section gray>
			<SalonOffer
				adCount={ad_count}
				content={contentWithHeavies}
				id={OFFER_LIST_ID}
				prefetchedOffers={prefetchedOffers}
				salonId={_id}
			/>
		</Section>
	)

	const ViewSwapper = useViewSwapper(VIEWS)

	useEffect(() => {
		const scrollToOffer = () => {
			if (router?.query?.strona > 1) {
				const list = document.getElementById(OFFER_LIST_ID)
				if (list)
					list.scrollIntoView({ behavior: 'smooth', block: 'start' })
			}
		}

		router.events.on('routeChangeComplete', scrollToOffer)
		return () =>
			router.events.off('routeChangeComplete', scrollToOffer)
	}, [router])

	return (
		<Layout logoonly navdown nofooterdiv>
			<Seo seo={seo} />
			<JsonLdSalon salon={salon} />
			<Layout.NavSpace />
			<Maybe.Desktop>
				<BreadcrumbBar
					explicit={[
						{ name: useT('Baza firm'), path: HOST + FIRM_BASE_PAGE },
						{ name: displayName },
					]}
					style={{ gap: 16 }}
				/>
			</Maybe.Desktop>

			<ViewSwapper>
				<div>
					<Maybe.Desktop>
						<Map
							height={300}
							radius={15}
							user={salon}
							zoom={10}
						>
							<Container>
								<div style={userDetailsDesktopStyle}>
									{salonDetailsFragment}
								</div>
							</Container>
						</Map>
					</Maybe.Desktop>
					<Maybe.Mobile>
						<div style={{ padding: 0 }}>{salonDetailsFragment}</div>
					</Maybe.Mobile>

					{salonOfferFragment}

					<Maybe.Mobile>
						<Map
							height={300}
							radius={15}
							user={salon}
							zoom={10}
						/>
					</Maybe.Mobile>
				</div>
				<div>{salonDescriptionFragment}</div>
			</ViewSwapper>
		</Layout>
	)
}

function FacebookLink({ user }) {
	const { facebook } = user?.contact || ''
	if (!facebook) return null
	return (
        <Link
            href={facebook}
            rel="nofollow noopener noreferrer"
            target="_blank"
		>

            <img alt="Facebook" src={HOST + "/new-svg/facebook.svg"} />

        </Link>
    );
}

export default SalonComponent
