import { useState, useEffect, useMemo } from 'react'
import Icon from '@components/shared/Icon'
import withHint from '@hoc/withHint'
import mix from '@utils/styles/mix'
import hintCache from './functions/hintCache'
import defaultStyles from 'styles/Input.module.css'

const Input = ({
    disabled,
    hint,
    hintMethod = withHint,
    isSearchInput,
    label,
    lastFormEventTime,
    methods,
    name,
    noclear,
    nolabel,
    onImmediateChange,
    onInput, // <- onInput used only by NIP
    register,
    required,
    selectlike,
    style = defaultStyles,
    unit,
    validations,
    ...rest }) => {

    const { onChange, setValue, getValues, watch } = methods || {}
    const value = getValues(name) || ''

    const [, setLastClear] = useState(Date.now())
    const [lastHintClear, setLastHintClear] = useState(Date.now())
    const [currentValue, setCurrentValue] = useState(rest.defaultValue || value)

    const { deps } = hint || {}

    const hasDeps = Array.isArray(deps)
    if (hasDeps) watch(deps)
    const depValues = hasDeps && getValues(deps)
    const allDepsHaveValues = depValues
        ? depValues.every(dep => Boolean(dep))
        : true

    const disable = disabled || (!allDepsHaveValues || value === 'brak')

    // support hints and cache hints
    const cache = hintCache(hint?.cache)
    const Hint = useMemo(() => hintMethod({
        cache,
        hint,
        methods,
        name,
        selectlike,
        lastHintClear,
        onImmediateChange,
        isSearchInput,
    }), [name, hint?.url, lastHintClear])

    const hasHint = Boolean(hint)
    // const isModel = name === 'model'
    // const modelValue = isModel && methods.watch('model', '')
    // const makeValue = isModel && methods.watch('marka', '')

    // clear adv. controlled inputs on form events:
    useEffect(() => {
        if (lastFormEventTime) setCurrentValue('')
    }, [lastFormEventTime])

    // useEffect(() => {
    //     if (isModel && modelValue === '' && makeValue === '' && modelValue !== currentValue ) {
    //         setCurrentValue('')
    //     }
    // }, [isModel, modelValue, currentValue, makeValue])

    const registerProps = register(name, validations)
    // support clear
    const clear = (setValue && !noclear && !disable)
        ? () => {
            if (onChange) methods.onChange({ name, value: '' })
            if (hasHint) setCurrentValue('')
            methods.setValue(name, '')

            if (name === 'marka') {
                methods.setValue('model', '')
                if (onChange) methods.onChange({ name: 'model', value: '' })
                setLastHintClear(Date.now())
            }

            setLastClear(Date.now())
            if (onImmediateChange) onImmediateChange('')
          }
        : undefined

    // support onInput event with (external value setter)
    const usableOnInput = onInput // <- onInput used only by NIP, login screen support for browser autofill
        ? (e) => onInput(e, setValue)
        : undefined

    // support control schemes
    let controlProps = {}

        // support character control via regexp
        if (hint?.allowOnly) {
            controlProps = {
                value: currentValue,
                onChange: (e) => {
                    if (registerProps) registerProps.onChange(e)

                    const { target: { value }} = e
                    const inputIsValid = !value || hint.allowOnly.test(value)
                    const validValue = inputIsValid
                        ? value
                        : currentValue

                    setCurrentValue(validValue)
                    if (onImmediateChange) onImmediateChange(validValue)
                }
            }
        }

        // support controlled hint match
        else if (hint?.exact || hint?.exactStart) {
            controlProps = {
                value: currentValue,
                onChange: (e) => {
                    if (registerProps) registerProps.onChange(e)

                    const { target: { value }} = e

                    if (!cache?.any()) return setCurrentValue('')
                    if (name === 'marka' && value == '' && clear) return clear()
                    if (name === 'model') {
                        const make = getValues('marka')
                        if (make == '') {
                            setLastHintClear(Date.now())
                            return setCurrentValue('')
                        }
                    }

                    const hints = cache.get()
                    const inputIsValid =
                           hint.exact && hints.some(hint => hint.toLowerCase().startsWith(value.toLowerCase()))
                        || hint.exactStart && (value === '' || hints.some(hint => inputString(value).startsWithCharsIn(hint)))

                    const validValue = inputIsValid
                        ? value
                        : currentValue

                    setCurrentValue(validValue)
                    if (onImmediateChange) onImmediateChange(validValue)
                }
            }
        }

        // support list mode (select only)
        else if (hint?.select) {
            controlProps = {
                value: currentValue,
                onChange: (e) => {
                    if (registerProps) registerProps.onChange(e)
                    setCurrentValue(currentValue)
                    if (onImmediateChange) onImmediateChange(currentValue)
                },
            }
        }

        else if (onImmediateChange) {
            controlProps = {
                onChange: (e) => {
                    if (registerProps) registerProps.onChange(e)
                    onImmediateChange(currentValue)
                }
            }
        }

        else { // every other case force onChange (default react hook form register method onChange rerenders form?)
            controlProps = {
                value: currentValue,
                onChange: ({ target: { value }}) => {
                    setCurrentValue(value)
                    if (onImmediateChange) onImmediateChange(value)
                },
            }
        }

    const input = (
        <Actions clear={clear} unit={unit} style={style}>
            <input
                id={name}
                name={name}
                className={mix([style.input, unit
                    ? style.unit_input
                    : style.action_input, disable && style.disabled])}
                autoComplete="off"
                required={required}
                onInput={usableOnInput} // <- onInput used only by NIP
                onKeyUp={
                    hasHint
                        ? ({ target: {value}}) => setCurrentValue(value)
                        : undefined}
                {...rest}
                {...registerProps}
                {...controlProps}
            />
        </Actions>
    )

    const core = hasHint
        ? <Hint currentValue={currentValue} setCurrentValue={setCurrentValue}>{input}</Hint>
        : <div className={style.pos}>{input}</div>

    return nolabel
        ? <div className={style.pos}>{core}</div>
        : (
            <div data-label="true">
                <label className={style.label_text} htmlFor={name} required={required}>
                    { label || name }
                </label>
                {core}
            </div>
          )
}

function Actions ({ children, clear, unit, style }) {
    return (
        <span className={style.action_wrapper}>
            { children }
            { clear && <span
                className={style.clear_action}
                onClick={clear}>

                <Icon>close</Icon></span> }
            { unit && <span className={style.unit}>{unit}</span> }
        </span>
    )
}

function inputString (input) {
    return {
        startsWithCharsIn: (reference) => {
            const _input = input.toLowerCase()
            const _reference = reference.toLowerCase()

            for (let i = 0, len = Math.min(input.length, reference.length); i < len; i++) {
                if (_input[i] != _reference[i]) return false
            }

            return true
        }
    }
}

export default Input