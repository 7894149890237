import dbConnect from 'db/dbConnect'
import { getEssentials, getSalonBySubdomain } from 'db/repo/listAdsSalon/getSalonData'

export { default } from '@components/salon/SalonComponent'

const HOST = process.env.HOST

export async function getServerSideProps (context) {
    const { query: { subdomain }} = context

    await dbConnect()

    const [salon, redirect] = await getSalonBySubdomain(subdomain)

    if (redirect || !salon) return {
        redirect: {
            permanent: true,
            destination: redirect || HOST,
        }
    }

    return {
        props: await getEssentials(context, salon, subdomain),
    }
}