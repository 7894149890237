import { useFormContext } from 'react-hook-form'
import Input from '@components/form/Input'
import Error from '@components/form/validation/Error'
import useT from '@hooks/useTranslation'

const ContextInput = ({ t, error, ...rest }) => {
    const label = useT(t || rest.name)
    const methods = useFormContext()

    return <>
        <Input {...rest}
            label={label}
            methods={methods}
            register={methods.register}
        />

        { error && <Error name={rest.name} /> }
    </>
}

export default ContextInput