export default function objectToSearch (object, exclude) {
    if (typeof object === 'object' && Object.keys(object).length > 0) {
        return '?' + Object.entries(object)
            .filter(entry => !exclude || !exclude.includes(entry[0]))
            .map(entry => `${entry[0]}=${encodeURIComponent(entry[1])}`)
            .join('&')

    } else {
        return ''
    }
}